<template>

<div>
  <!-- vuetify component:  v-app-bar  https://vuetifyjs.com/en/components/app-bars/
  LOOK UP: https://codepen.io/kematzy/pen/oMgrjE
  -->
  <v-toolbar>
      <v-toolbar-items  class="hidden-sm-and-down">

        <v-col  cols="12" >
          <v-btn :to ="({name:'Home'})"  text 
          height = 90% 
          >
            HOME
          </v-btn> |
          <v-btn :to ="({name:'Blog'})" text height = 90%> 
            BLOG
          </v-btn> |
          <v-btn :to ="({name:'Map'})" text height = 90%> 
            MAP
          </v-btn> |
          <v-btn :to ="({name:'About'})" text height = 90%>
            ABOUT 
          </v-btn>
          <!-- <v-btn :to ="({name:'FunFacts'})" text height = 90%> 
            RANDOM
          </v-btn> -->
        </v-col>
      </v-toolbar-items>
     <!-- <v-toolbar-title
          class="hidden-sm-and-down black--text">
      <p 
        style="vertical-alignment:middle; margin:0px;">  ELENA & JAKOB </p> 
    </v-toolbar-title>       -->
    <!-- <v-spacer></v-spacer>   -->
    <v-menu>
      <template  v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            class="hidden-md-and-up"
            v-bind="attrs"
            v-on="on"
          >
          </v-app-bar-nav-icon>
      </template>
        <v-list>
        <v-list-item 
          v-for="(item,index) in menuItems" 
          :key="index"
          :to="item.routerlink">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>   
      </v-list>
    </v-menu>    
   </v-toolbar>


</div>
</template>

<script>
  export default {
        data: () => ({
            drawer: false,
            group: null,
            menuItems: [
                {title: 'Home',
                routerlink: {name: 'Home'}}, 
                {title: 'Blog',
                routerlink: {name: 'Blog'}},
                {title: 'Map',
                routerlink: {name: 'Map'}}, 
                {title: 'About',
                routerlink: {name: 'About'}},                    
                // {title: 'Fun facts',
                // routerlink: {name: 'FunFacts'}},

            ]
        }),
        

    //
  }
</script>

<style lang="scss" scoped>

  .md-layout-item {
    height: 40px;
  }

</style>